import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import OpenSale from '../components/OpenSale';
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Accordion from "react-bootstrap/Accordion";
import Countdown from "react-countdown";
import "bootstrap/dist/css/bootstrap.min.css";


function ComingSoon() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your Holy Rock NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 4,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  const getData = () => {
    if (blockchain.account && blockchain.smartContract) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {

    console.log(name);

    if (completed) {
      // Render a completed state
      console.log(blockchain);
      return <OpenSale data={data} blockchain={blockchain}></OpenSale>
    } else {
      // Render a countdown
      return (
        <div className="timer-block" style={{
          backgroundColor: "var(--accent)",
          padding: 5,
          borderRadius: 24,
          border: "4px solid var(--secondary)",
          boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
          maxWidth: "100%",
          minWidth: "40%",
          opacity: "0.95",
          color: "white",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          minHeight: "300px"
        }}>
          <s.TextTitle
            style={{
              textAlign: "center",
              fontSize: "30px",
              fontWeight: "bold",
              color: "var(--accent-text)",
            }}
          >
            {data.totalSupply} / {CONFIG.MAX_SUPPLY}
          </s.TextTitle>
          <br></br><br></br><br></br><br></br>
          <s.TextTitle
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              color: "var(--accent-text)",
            }}
          >
            The public sale will start in {days} Days, {hours} Hours, {minutes} Minutes and {seconds} Seconds

          </s.TextTitle>
          
          <br></br><br></br><br></br>
          <s.TextDescription style={{ verticalAlign: "text-bottom", color: "white" }}>
            The starting price of each Holy Rock will be 0.6 ETH.<br></br>
            Each wallet will be able to hold a maximum amount of 4 Holy Rocks.<br></br>
          </s.TextDescription>
        </div>
      )
    }
  };

  return (
    <Countdown date={new Date()} renderer={renderer}>
    </Countdown>
  );
}

export default ComingSoon;