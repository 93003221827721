import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import OpenSale from './components/OpenSale';
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Countdown from "react-countdown";
import ComingSoon from './components/ComingSoon';
import EarlyComingSoon from './components/EarlyComingSoon';
import '@google/model-viewer';
import "bootstrap/dist/css/bootstrap.min.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';


function App() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <Router>

      <s.Screen style={{
        width: "100%",
        backgroundColor: "black",
      }}>

        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark rounded" style={{
          width: "100%",
          zIndex: "3",
        }}>
          <a className="navbar-brand text-info font-weight-bolder" href="#home">
            <img src={"config/images/3.png"} alt="Logo" className="vertical-align-middle" style={{
              width: "200px",
              paddingLeft: "20px",
            }} />
          </a>

          <button className="custom-toggler navbar-toggler bg-dark" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse navbar-nav mr-auto`} id="navbarsExample09" style={{
            justifyContent: "right",
          }}>
            <Nav.Link href="#home" style={{
              float: "right",
            }}>Home</Nav.Link>

            <Nav.Link href="#roadmap" style={{
              float: "right",
            }}>Roadmap</Nav.Link>
            <Nav.Link href="#team" style={{
              float: "right",
            }}>Team</Nav.Link>
            <Nav.Link href="#qa" style={{
              float: "right",
            }}>Q&A</Nav.Link>

            <Nav.Link href="https://opensea.io/collection/holyrocknftvproject" style={{
              float: "right",
            }}>          <img src={"config/images/opensea.png"} alt="Logo" className="vertical-align-middle" style={{
              width: "30px",
            }} /></Nav.Link>

            <Nav.Link href="https://www.instagram.com/holyrocknft" style={{
              float: "right",
            }}>          <img src={"config/images/instagram.png"} alt="Logo" className="vertical-align-middle" style={{
              width: "30px",
            }} /></Nav.Link>

            <Nav.Link href="https://twitter.com/holyrocknft" style={{
              float: "right",
            }}>          <img src={"config/images/twitter.png"} alt="Logo" className="vertical-align-middle" style={{
              width: "30px",
            }} /></Nav.Link>

            <Nav.Link href="https://discord.gg/kHazEY9m3V" style={{
              float: "right",
              paddingRight: "50px"
            }}>
              <s.StyledButton style={{
                width: "120%",
                height: "100%",
              }}>
                Join Our Discord
              </s.StyledButton></Nav.Link>
          </div>
        </nav>

        <s.Container
          id={"home"}
          ai={"center"}
          style={{
            padding: 24, backgroundColor: "var(--primary)", display: "flex", flexDirection: "column",
          }}
          image={"/config/images/bg.png"}
        >
          <s.SpacerLarge />
          <s.SpacerLarge />

          <s.Container
            style={{
              display: "flex", flexDirection: "row", gap: "2px",
              justifyContent: "center"
            }}
          >        <s.StyledLogo style={{
            position: "relative",
            order: "0",
            alignSelf: "center",
            paddingTop: "5%",
            maxWidth: "350px",
            flex: "1",
          }}
            alt={"logo"} src={"/config/images/logo.png"} />
          </s.Container>

          <s.SpacerSmall />

          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.SpacerLarge />

          <Routes>
            <Route path="*" element={<ComingSoon />}  />
            <Route path="/blackfriday" element={<EarlyComingSoon />} />
          </Routes>

          <s.SpacerLarge />
          <s.SpacerMedium />

        </s.Container>

        <s.Container
          style={{
            height: "1920px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "black",
          }}>

          <s.Container style={{
            width: "100%",
            height: "100%"
          }}>
            <s.SpacerLarge />
            <s.SpacerLarge />

            <section id="roadmap-content" className="section--spacing position-relative">

              <div className="container-fluid" style={{
                position: "relative",
              }}>

                <div className="row justify-content-center">
                  <h2 className="h1 text-uppercase text-center col-12 fw-900 team" style={{
                    color: "white",
                  }}>Welcome</h2>
                </div>
                <s.SpacerLarge />
                <s.SpacerLarge />


                <div className="row align-items-center roadmap-step" ai={"center"} style={{
                  alignSelf: "center",
                  paddingLeft: "10%",
                  paddingRight: "10%"
                }}>

                  <s.SpacerLarge />
                  <s.SpacerLarge />
                  <s.SpacerLarge />
                  <s.SpacerLarge />
                  <s.SpacerLarge />

                  <div style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap"
                  }}>

                    <div className="col-8 roadmap-details" style={{
                      minWidth: "200px",
                      flex: "1"
                    }}>
                      <span className="roadmap-title">Holy Rock NFT</span><br></br>
                      Holy Rock NFT is an exclusive community project that combines the business world and technological progress with Jewish faith and spirit.<br></br><br></br>
                      The basis for the project is a globally unique and first-of-its-kind initiative: using digital technologies to create an accurate imaging of the Western Wall stones.<br></br><br></br>
                      The stones of the Western Wall are thousands of years old. Each stone has its own distinctive appearance, dimensions, weight, shape and age. Some of the stones are particularly prominent and rare, due to their gigantic dimensions or the unusual inscriptions embedded in them.<br></br><br></br>
                      Each member of the Holy Rock NFT community project will have sole possession and ownership of a simulated image, identical to one of the original Western Wall stones. Every crack, chisel and historical inscription that exists on the original stone will appear on the image.<br></br><br></br>
                      Each image is marked with an NFT (Non-Fungible Token) code, used to identify original, exclusive and non-copyable digital works.<br></br><br></br>
                    </div>
                    <img src={"/config/images/stone0003_thumbnail.PNG"}
                      style={{
                        minWidth: "200px",
                        maxWidth: "30%",
                        position: "relative",
                      }} />
                  </div>
                </div>

              </div>

            </section>

          </s.Container>
        </s.Container>

        <s.Container
          style={{
            height: "1920px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "black",
          }}>

          <s.Container
            style={{
              height: "1920px",
              width: "100%",
              overflowX: "hidden",
              position: "absolute",
            }}>

            <video id='roadmap' autoPlay loop muted src="/config/images/The Western Wall (3).mp4" type='video/mp4' style={{
              backgroundImage: "none",
              zIndex: "0",
            }}>
            </video>
          </s.Container>

          <s.Container style={{
            width: "100%",
            height: "100%"
          }}>
            <s.SpacerLarge />
            <s.SpacerLarge />

            <section id="roadmap-content" className="section--spacing position-relative">

              <div className="container-fluid" style={{
                position: "relative",
              }}>

                <div className="row justify-content-center">
                  <h2 className="h1 text-uppercase text-center col-12 fw-900 team" style={{
                    color: "white",
                  }}>Roadmap</h2>
                </div>

                <div className="row align-items-center roadmap-step" ai={"center"} style={{
                  alignSelf: "center",
                  paddingLeft: "10%",
                  paddingRight: "10%"
                }}>
                  <div style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap"
                  }}>

                    <div className="col-8 roadmap-details" style={{
                      minWidth: "200px",
                      flex: "1"
                    }}>
                      <span className="roadmap-title">Public Sale</span><br></br>
                      On November 27, 2021, the public sale opens.<br></br>
                      2357 Holy Rocks will be offered in the public sale.<br></br> Of these, 125 stones for special personal prayers will be allocated.<br></br>
                      On December 5, 2021, we will reveal the Holy Rocks and the "Rarity Table" will be made available to the general public.<br></br><br></br>

                      <span className="roadmap-title">Project Vision</span><br></br>
                      Once the institutional stages of this project is completed, we will ensure to continue long-term development.<br></br>
                      We will work to create a global technological-business ecosystem, alongside the expression of judicial values of faith and spirit.<br></br>
                      We will create a community from innovative projects, where different investments will be generated from the root of the original content.<br></br>
                      Striving at an accelerated pace to be the first pioneering spiritual enterprise in the world of METAVERSE.<br></br>
                      We are very excited to invite you to the launch of this futuristic project combining business, technology and spirit together.<br></br>

                    </div>
                    <img src={"/config/images/stone0002_thumbnail.PNG"}
                      style={{
                        minWidth: "200px",
                        maxWidth: "30%",
                        position: "relative",
                      }} />
                  </div>
                </div>

              </div>

            </section>

          </s.Container>
        </s.Container>
        <s.Container
          style={{
            height: "1920px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "black",
            zIndex: "1"
          }}>


          <s.Container style={{
            width: "100%",
            height: "100%"
          }}>
            <s.SpacerLarge />
            <s.SpacerLarge />

            <section id="roadmap-content" className="section--spacing position-relative">

              <div className="container-fluid" style={{
                position: "relative",
              }}>

                <div className="row align-items-center roadmap-step" ai={"center"} style={{
                  alignSelf: "center",
                  paddingLeft: "10%",
                  paddingRight: "10%"
                }}>
                  <div style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap"
                  }}>

                    <div className="col-8 roadmap-details" style={{
                      minWidth: "200px",
                      flex: "1"
                    }}>
                      <span className="roadmap-title">Community</span><br></br>
                      Business meetings and the establishment of a significant economic community, based on the mutual belief in the Jewish spirit and faith.<br></br>
                      Each year, all stone owners will be invited to quarterly meetings, of a business-faith nature.<br></br> The purpose of the meetings is to fulfill the community mission of the project: to create business connections and business opportunities, and establish a community with economic strengths.<br></br>
                      The Holy Rock NFT project has only 2,357 stones, so this is the maximum and absolute number of community members.<br></br>From this limited and exclusive series, there is a group of even more unique stones, with visual, structural and historical characteristics that make them particularly rare.

                    </div>
                    <img src={"/config/images/stone0001_thumbnail.PNG"}
                      style={{
                        minWidth: "200px",
                        maxWidth: "30%",
                        position: "relative",
                      }} />
                  </div>
                </div>

              </div>

            </section>

          </s.Container>
        </s.Container>
        <s.Container
          style={{
            height: "1920px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "black",
          }}>

          <s.Container
            style={{
              height: "1920px",
              width: "100%",
              overflowX: "hidden",
              position: "absolute",
            }}>

            <video id='roadmap' autoPlay loop muted src="/config/images/The Western Wall (2).mp4" type='video/mp4' style={{
              backgroundImage: "none",
              zIndex: "0",
            }}>
            </video>
          </s.Container>



          <s.Container style={{
            width: "100%",
            height: "100%"
          }}>
            <s.SpacerLarge />
            <s.SpacerLarge />

            <section id="roadmap-content" className="section--spacing position-relative">

              <div className="container-fluid" style={{
                position: "relative",
              }}>

                <div className="row align-items-center roadmap-step" ai={"center"} style={{
                  alignSelf: "center",
                  paddingLeft: "10%",
                  paddingRight: "10%"
                }}>
                  <div style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap"
                  }}>

                    <div className="col-8 roadmap-details" style={{
                      minWidth: "200px",
                      flex: "1"
                    }}>
                      <span className="roadmap-title">Judaic Studies Program (Kolel)</span><br></br>
                      Establishment of the Judaic Studies Program (Kolel) and Prayers for the wellbeing of the stone owners.<br></br>
                      The Holy Rock NFT community will fund a Kolel, with at least 26 students, who will pray all the annual prayers, and bless the stone holders on a daily basis. <br></br><br></br>
                      In addition to the general prayer for the wellbeing of all the stone owners, some of the stones will also grant their owners a personal, designated blessing, such as a blessing for a match, a blessing for livelihood, a blessing for health and more, allocated as follows:<br></br><br></br>
                      25 Holy Rocks will grant their owners a prayer for a match.<br></br>
                      25 Holy Rocks will grant their owners a prayer for success.<br></br>
                      25 Holy Rocks will grant their owners a prayer for livelihood.<br></br>
                      25 Holy Rocks will grant their owners a prayer for health.<br></br>
                      25 Holy Rocks will grant their owners a prayer against the evil eye.<br></br>
                    </div>
                    <img src={"/config/images/stone0004_thumbnail.PNG"}
                      style={{
                        minWidth: "200px",
                        maxWidth: "30%",
                        position: "relative",
                      }} />
                  </div>
                </div>

              </div>

            </section>

          </s.Container>
        </s.Container>


        <s.Container
          style={{
            height: "1920px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "black",
            zIndex: "1"
          }}>

          <s.Container style={{
            width: "100%",
            height: "1920px"
          }}>
            <s.SpacerLarge />
            <section
              id="team"
              className="section--spacing position-relative"
            >
              <div className="container-fluid" style={{
                position: "relative"
              }}>
                <div className="row">
                  <h2 className="h1 text-uppercase text-center col-12 fw-900 team">
                    Team
                  </h2>
                  <div className="row align-items-center roadmap-step" ai={"center"} style={{
                    alignSelf: "center",
                    paddingLeft: "10%",
                    paddingRight: "10%"
                  }}>
                    <div className="col-9 roadmap-details" style={{
                      minWidth: "230px",
                      alignSelf: "center",
                    }}>
                      Holy Rock NFT was founded by a strong team of digital moguls, including experienced entrepreneurs, artists, blockchain experts, and senior marketing specialists.
                      <br></br>
                      It has been our goal as a unique team in this incredible project to create a unified community around a product that is appreciated and makes full use of its qualities.
                      <br></br>
                      <br></br>
                    </div>
                  </div>


                  <div className="d-flex flex-wrap justify-content-center team-avatars" style={{
                    display: "flex",
                    alignItems: "center !important",
                    flexFlow: "row !important",
                    minWidth: "5%",
                    maxWidth: "100%",
                    paddingBottom: "100px"
                  }}>
                    <div className="team-member-item px-3 col-lg-3 col-md-4 col-12">
                      <div className="team--member-avatar">
                        <img
                          src="./config/images/Avi.png"
                          alt="Avi Cohen"
                          style={{
                            flexShrink: "0",
                            minWidth: "5%",
                            maxWidth: "100%",
                          }} />
                      </div>
                      <div className="team-member-bio">
                        <h4 className="team-member-name fw-900" style={{
                          color: "white"
                        }}>Avi Cohen</h4>
                        <span className="team-member-position"
                        >Business &amp; Community Expert</span
                        >
                      </div>
                    </div>
                    <div className="team-member-item px-3 col-lg-3 col-md-4 col-12">
                      <div className="team--member-avatar">
                        <img
                          src="./config/images/Noam.png"
                          alt="Noam Pollak"
                          style={{
                            flexShrink: "0",
                            minWidth: "5%",
                            maxWidth: "100%",
                          }} />
                      </div>
                      <div className="team-member-bio">
                        <h4 className="team-member-name fw-900" style={{
                          color: "white",
                        }}>Noam Pollak</h4>
                        <span className="team-member-position"
                        >Marketing &amp; Product Expert</span
                        >
                      </div>
                    </div>                 
                  </div>
                </div>

              </div>
            </section>
          </s.Container>
        </s.Container>
        <s.Container
          style={{
            height: "1920px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "black",
          }}>

          <s.Container
            style={{
              height: "1020px",
              width: "100%",
              overflowX: "hidden",
              position: "absolute",
            }}>

            <video id='qa' autoPlay loop muted src="/config/images/The Western Wall (3).mp4" type='video/mp4' style={{
              backgroundImage: "none",
              zIndex: "0",
            }}>
            </video>
          </s.Container>

          <s.Container style={{
            padding: "50px",
            height: "100%",
            zIndex: "1",
            backgroundColor: "transparent",
            display: "flex",
          }}>
            <div className="row justify-content-center" style={{
              width: "100%",
              justifySelf: "center"
            }}>
              <h2 className="h1 text-uppercase text-center col-12 fw-900 team" style={{
                color: "white",
              }}>FREQUENTLY ASKED QUESTIONS</h2>
            </div>

            <Accordion style={{
              width: "100%",
              backgroundColor: "transparent",
              paddingBottom: "50px"
            }}>
              <Accordion.Item eventKey="1" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent"
              }}><Accordion.Header>What is Metamask?</Accordion.Header>
                <Accordion.Body>
                  Metamask is a crypto wallet that can store your Ethereum, and is needed to purchase and mint a Holy Rock. Having a wallet gives you an Ethereum address (i.e. 0xABCD….1234), this is where your NFT will be stored. Learn more about Metamask and how easy it is to use over here! https://metamask.io.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent"

              }}><Accordion.Header>How many Holy Rocks will be available?</Accordion.Header>
                <Accordion.Body>
                  A grand total of 2357 NFTs will be available. 2% will be used for all the people involved in the project, promotions and partnerships.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent"
              }}> <Accordion.Header>Will there be a limit on how many rocks we can hold?</Accordion.Header>
                <Accordion.Body>
                  Yes, each wallet can hold a maximum of 4 Holy Rocks.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent",
              }}>
                <Accordion.Header>Is there a presale option?</Accordion.Header>
                <Accordion.Body >
                  No, we have a limited amount of Holy Rocks that can only be purchased in the public sale.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent"

              }}><Accordion.Header>What is the price of a Holy Rock?</Accordion.Header>
                <Accordion.Body>
                  Once the public sale starts, the price will be 0.35 ETH.<br></br>
                  Each hour the price will increase by 0.05 ETH until a maximum price of 0.6 ETH per Holy Rock.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent"
              }}> <Accordion.Header>Will there be a limit on how many rocks we can mint?</Accordion.Header>
                <Accordion.Body>
                  Yes, there's a maximum of 4 rocks that can be minted in the public sale.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent"

              }}><Accordion.Header>Where can I view my Holy Rock?</Accordion.Header>
                <Accordion.Body>
                  Once you have minted a Holy Rock NFT, you will be able to see it by connecting to your crypto wallet using OpenSea.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent"

              }}><Accordion.Header>Is there any use for my Holy Rock?</Accordion.Header>
                <Accordion.Body>
                  Yes, there are several uses. First of all, it offers you the opportunity to take part in the keeping of a Kolel, where the mitzvah of Torah study is observed by students who also pray for you, every day. After holding your NFT for a month, you can send your name and proof of ownership of the NFT to the Kolel and your name will be incorporated in the daily prayers.
                  In addition, the NFT allows you to take part in an exclusive community that, every few months, holds a meaningful meeting, addressing business and faith.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent"

              }}><Accordion.Header>When I buy a Holy Rock, do I get ownership of a Western Wall stone?</Accordion.Header>
                <Accordion.Body>
                  No. Purchasing a Holy Rock NFT gives you ownership of a unique and rare image of a stone from the Western Wall, that was photographed and specially prepared. Each stone was documented individually, down to the minutest detail.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="10" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent"

              }}><Accordion.Header>What are the 125 Holy Rocks for Special Prayers?</Accordion.Header>
                <Accordion.Body>
                  In addition to the general prayer for the wellbeing of all the stone owners, 125 Holy Rocks have been dedicated for special blessings:<br></br>
                  25 Holy Rocks towards a prayer for a match.<br></br>
                  25 Holy Rocks towards a prayer for success.<br></br>
                  25 Holy Rocks towards a prayer for livelihood.<br></br>
                  25 Holy Rocks towards a prayer for health.<br></br>
                  25 Holy Rocks towards a prayer against the evil eye.<br></br>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="11" style={{
                width: "100%",
                paddingBottom: "10px",
                backgroundColor: "transparent"

              }}><Accordion.Header>Why would I want to own a Holy Rock NFT?</Accordion.Header>
                <Accordion.Body>
                  The ownership allows the right to study and be blessed with the prayers of the twenty-six Kolel students (Talmid Chacham) each day.
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
            <a href="/config/images/terms.txt"><s.TextDescription style={{
              color: "white"
            }}>Terms & Conditions</s.TextDescription></a>

            <s.Container style={{
              zIndex: "1",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              gap: "6px"
            }}>

              <a href="https://opensea.io/collection/holyrocknftvproject" style={{
                float: "right",
              }}>          <img src={"config/images/opensea.png"} alt="Logo" className="vertical-align-middle" style={{
                width: "30px",
              }} /></a>

              <a href="https://www.instagram.com/holyrocknft" style={{
                float: "right",
              }}>          <img src={"config/images/instagram.png"} alt="Logo" className="vertical-align-middle" style={{
                width: "30px",
              }} /></a>

              <a href="https://twitter.com/holyrocknft" style={{
                float: "right",
              }}>          <img src={"config/images/twitter.png"} alt="Logo" className="vertical-align-middle" style={{
                width: "30px",
              }} /></a>

              <a href="https://discord.gg/kHazEY9m3V" style={{
                float: "right",
              }}>
                <s.StyledButton style={{
                  width: "120%",
                  height: "100%",
                }}>
                  Join Our Discord
                </s.StyledButton></a>
            </s.Container>


          </s.Container>
        </s.Container>

      </s.Screen>
    </Router>
  );
}

export default App;
