import React, { useEffect, useState, useRef } from "react";
import { connect as con } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import "bootstrap/dist/css/bootstrap.min.css";

export class OpenSale extends React.Component {
  constructor() {
    super();
    this.state = {
      claimingNft: false,
      setClaimingNft: false,
      feedback: `Click buy to mint your Holy Rock NFT.`,
      setFeedback: `Click buy to mint your Holy Rock NFT.`,
      mintAmount: 1,
      CONFIG: {
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
          NAME: "",
          SYMBOL: "",
          ID: 0,
          NFT_NAME: "",
          SYMBOL: "",
          MAX_SUPPLY: 4,
          WEI_COST: 0,
          DISPLAY_COST: 0,
          GAS_LIMIT: 0,
          MARKETPLACE: "",
          MARKETPLACE_LINK: "",
          SHOW_BACKGROUND: false,
        }
      }
    }
  }

  claimNFTs() {
    let cost = this.state.CONFIG.WEI_COST;
    console.log("price: ", cost);
    let gasLimit = this.state.CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * this.state.mintAmount);
    let totalGasLimit = String(gasLimit * this.state.mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    this.setState({ feedback: `Minting your ${this.state.CONFIG.NFT_NAME}...` });
    this.setState({ claimingNft: true });

    this.props.blockchain.smartContract.methods
      .mint(this.state.mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: this.state.CONFIG.CONTRACT_ADDRESS,
        from: this.props.blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        this.setState({ feedback: "Sorry, something went wrong please try again later." });
        this.setState({ claimingNft: false });
      })
      .then((receipt) => {
        console.log(receipt);
        this.setState({ feedback: `WOW, the ${this.state.CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.` });
        this.setState({ claimingNft: false });
        fetchData(this.props.blockchain.account);
      });
  };

  decrementMintAmount() {
    let newMintAmount = this.state.mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }

    this.setState({ mintAmount: newMintAmount });
  };

  incrementMintAmount() {
    let newMintAmount = this.state.mintAmount + 1;
    if (newMintAmount > 4) {
      newMintAmount = 4;
    }
    this.setState({ mintAmount: newMintAmount });
  };

  getData() {
    if (this.props.blockchain.account && this.props.blockchain.smartContract) {
      this.props.fetchData(this.props.blockchain.account);
    }
  };

  getConfig = async () => {
    let configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    let config = await configResponse.json();
    this.setState({ CONFIG: config });
  };

  truncate(input, len) {
    input.length > len ? `${input.substring(0, len)}...` : input;
  }

  componentDidMount() {
    this.getConfig();
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.blockchain.account !== this.props.blockchain.account) {
      this.getData();
    }
  }

  //   useEffect(() => {
  //     getConfig();
  //   }, []);

  //  useEffect(() => {
  //    getData();
  //  }, [this.props.blockchain.account]);

  render() {
    if (this.props.data && this.props.blockchain) {
      return (
        <s.Container
          jc={"center"}
          ai={"center"}
          style={{
            backgroundColor: "var(--accent)",
            padding: 5,
            borderRadius: 24,
            border: "4px solid var(--secondary)",
            boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            width: "70%",
            opacity: "0.95"
          }}
        >
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            <s.StyledLink target={"_blank"} href={this.state.CONFIG.SCAN_LINK}>
              {this.truncate(this.state.CONFIG.CONTRACT_ADDRESS, 15)}
            </s.StyledLink>
          </s.TextDescription>
          <s.SpacerSmall />
          {Number(this.props.data.totalSupply) >= this.state.CONFIG.MAX_SUPPLY ? (
            <>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                The sale has ended.<br></br>
                In accordance with the settlement we reached with the State of Israel (Ministry of Religions), as part of the proceedings 67258-11-21, we would like to update that we have decided to agree to leave the temporary restraining order, which prevents us from selling additional Holy Rocks NFT, however, we will make it clear that all other activities planned for the community will take place as scheduled.<br></br>
                Clarification Notice: This project is not affiliated with the Ministry of Religious Services or the Western Wall Heritage Foundation.
              </s.TextTitle>
              <s.TextDescription
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                You can still find {this.state.CONFIG.NFT_NAME} on
              </s.TextDescription>
              <s.SpacerSmall />
              <s.StyledLink target={"_blank"} href={this.state.CONFIG.MARKETPLACE_LINK}>
                {this.state.CONFIG.MARKETPLACE}
              </s.StyledLink>
            </>
          ) : (
            <>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                1 {this.state.CONFIG.SYMBOL} costs {this.state.CONFIG.DISPLAY_COST}{" "}
                {this.state.CONFIG.NETWORK.SYMBOL}.
              </s.TextTitle>
              <s.SpacerXSmall />
              <s.TextDescription
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                Excluding gas fees.
              </s.TextDescription>
              <s.SpacerSmall />
              {!this.props.blockchain.account ||
                !this.props.blockchain.smartContract ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                In accordance with the settlement we reached with the State of Israel (Ministry of Religions), as part of the proceedings 67258-11-21, we would like to update that we have decided to agree to leave the temporary restraining order, which prevents us from selling additional Holy Rocks NFT, however, we will make it clear that all other activities planned for the community will take place as scheduled.
                Clarification Notice: This project is not affiliated with the Ministry of Religious Services or the Western Wall Heritage Foundation.

                  </s.TextDescription>
                  <s.SpacerSmall />
                  <s.StyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.connect();
                      this.getData();
                    }}
                  >
                    CONNECT
                  </s.StyledButton>
                  {this.props.blockchain.errorMsg ? (
                    <>
                      <s.SpacerSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {this.props.blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              ) : (
                <>
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 50,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {this.props.data.totalSupply} / {this.state.CONFIG.MAX_SUPPLY}
                  </s.TextTitle>

                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    {this.state.feedback}
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <s.StyledRoundButton
                      style={{ lineHeight: 0.4 }}
                      disabled={this.state.claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        this.decrementMintAmount();
                      }}
                    >
                      -
                    </s.StyledRoundButton>
                    <s.SpacerMedium />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {this.state.mintAmount}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.StyledRoundButton
                      disabled={this.state.claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        this.incrementMintAmount();
                      }}
                    >
                      +
                    </s.StyledRoundButton>
                  </s.Container>
                  <s.SpacerSmall />
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <s.StyledButton
                      disabled={this.state.claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        this.claimNFTs();
                        this.getData();
                      }}
                    >
                      {this.state.claimingNft ? "BUSY" : "BUY"}
                    </s.StyledButton>
                  </s.Container>
                </>
              )}
            </>
          )}
          <s.SpacerMedium />
        </s.Container>

      )
    } else return <div></div>;
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    connect: () => { dispatch(connect()) },
    fetchData: (id) => { dispatch(fetchData(id)) }
  }
}

const foo = con(null, mapDispatchToProps)(OpenSale);
export default foo;